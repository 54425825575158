.cookie-styles {
  .tooltipButton {
    margin: 0;
    padding: 0;
    height: 18px;
    width: 18px;
    background: none;
    border: none;
    cursor: pointer;
  }

  input,
  textarea,
  select {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  input {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  img {
    display: block;
  }
  h1 {
    margin: 0;
    white-space: pre-wrap;
    font-size: 36px;
  }
  h2 {
    margin: 0;
    font-size: 28px;
  }
  h3 {
    margin: 0;
    font-size: 20px;
  }
  h6 {
    margin: 0;

    font-size: 12px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
  }
  img {
    display: block;
  }

  ul {
    text-align: left !important;
    padding: 0;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}

.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 20px !important;
}
.mr-15 {
  margin-right: 30px !important;
}
.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 20px !important;
}
.ml-15 {
  margin-left: 30px !important;
}
.ml-20 {
  margin-left: 40px !important;
}

.center-align {
  text-align: left !important;
}
.center-align {
  text-align: center !important;
}
.right-align {
  text-align: right !important;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}
