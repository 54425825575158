.cookie-styles .tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.cookie-styles input,
.cookie-styles textarea,
.cookie-styles select {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0 !important;
}

.cookie-styles input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cookie-styles img {
  display: block;
}

.cookie-styles h1 {
  margin: 0;
  white-space: pre-wrap;
  font-size: 36px;
}

.cookie-styles h2 {
  margin: 0;
  font-size: 28px;
}

.cookie-styles h3 {
  margin: 0;
  font-size: 20px;
}

.cookie-styles h6 {
  margin: 0;
  font-size: 12px;
}

.cookie-styles h1,
.cookie-styles h2,
.cookie-styles h3,
.cookie-styles h4,
.cookie-styles h5,
.cookie-styles h6 {
  margin-bottom: 20px;
}

.cookie-styles p {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}

.cookie-styles img {
  display: block;
}

.cookie-styles ul {
  text-align: left !important;
  padding: 0;
}

.cookie-styles a {
  text-decoration: underline;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .cookie-styles h1 {
    font-size: 40px;
  }
  .cookie-styles h2 {
    font-size: 30px;
  }
  .cookie-styles h3 {
    font-size: 20px;
  }
  .cookie-styles p {
    font-size: 16px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}

.mr-10 {
  margin-right: 20px !important;
}

.mr-15 {
  margin-right: 30px !important;
}

.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}

.ml-10 {
  margin-left: 20px !important;
}

.ml-15 {
  margin-left: 30px !important;
}

.ml-20 {
  margin-left: 40px !important;
}

.center-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}
